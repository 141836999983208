import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-toolbar" }
const _hoisted_4 = {
  class: "d-flex justify-content-end",
  "data-kt-subscription-table-toolbar": "base"
}
const _hoisted_5 = { class: "svg-icon svg-icon-2" }
const _hoisted_6 = { class: "svg-icon svg-icon-2" }
const _hoisted_7 = { class: "svg-icon svg-icon-2" }
const _hoisted_8 = { class: "svg-icon svg-icon-2" }
const _hoisted_9 = { class: "card-body pt-3" }
const _hoisted_10 = { class: "table-responsive" }
const _hoisted_11 = { class: "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" }
const _hoisted_12 = { class: "d-flex align-items-center" }
const _hoisted_13 = { class: "d-flex justify-content-start flex-column" }
const _hoisted_14 = { class: "text-dark fw-bolder text-hover-primary mb-1 fs-6" }
const _hoisted_15 = { class: "text-muted text-hover-primary fw-bold text-muted d-block fs-7" }
const _hoisted_16 = { class: "text-end" }
const _hoisted_17 = { class: "text-muted text-hover-primary fw-bold text-muted d-block fs-7" }
const _hoisted_18 = { class: "text-muted text-hover-primary fw-bold text-muted d-block fs-7" }
const _hoisted_19 = { class: "text-end" }
const _hoisted_20 = {
  key: 0,
  class: "text-muted text-hover-primary fw-bold text-muted d-block fs-7"
}
const _hoisted_21 = {
  key: 1,
  class: "text-muted text-hover-primary fw-bold text-muted d-block fs-7"
}
const _hoisted_22 = {
  key: 2,
  class: "text-muted text-hover-primary fw-bold text-muted d-block fs-7"
}
const _hoisted_23 = {
  key: 3,
  class: "text-muted text-hover-primary fw-bold text-muted d-block fs-7"
}
const _hoisted_24 = {
  key: 4,
  class: "text-muted text-hover-primary fw-bold text-muted d-block fs-7"
}
const _hoisted_25 = {
  key: 5,
  class: "text-muted text-hover-primary fw-bold text-muted d-block fs-7"
}
const _hoisted_26 = {
  key: 6,
  class: "text-muted text-hover-primary fw-bold text-muted d-block fs-7"
}
const _hoisted_27 = {
  key: 7,
  class: "text-muted text-hover-primary fw-bold text-muted d-block fs-7"
}
const _hoisted_28 = { class: "d-flex justify-content-start flex-column" }
const _hoisted_29 = { class: "text-muted text-hover-primary fw-bold text-muted d-block fs-7" }
const _hoisted_30 = { class: "text-muted text-hover-primary fw-bold text-muted d-block fs-7" }
const _hoisted_31 = { class: "text-muted fw-bold text-end" }
const _hoisted_32 = { class: "text-muted text-hover-primary fw-bold text-muted d-block fs-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.downloadTemplate && _ctx.downloadTemplate(...args))),
            type: "button",
            class: "btn btn-light-primary me-3"
          }, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr078.svg" })
            ]),
            _cache[6] || (_cache[6] = _createTextVNode(" Download Template "))
          ]),
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-light-primary me-3",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.triggerFileInput && _ctx.triggerFileInput(...args)))
          }, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr076.svg" })
            ]),
            _cache[7] || (_cache[7] = _createTextVNode(" Import "))
          ]),
          _createElementVNode("input", {
            type: "file",
            ref: "fileInput",
            style: {"display":"none"},
            onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleFileUpload && _ctx.handleFileUpload(...args))),
            accept: ".xlsx, .xls"
          }, null, 544),
          _createElementVNode("button", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.generateToken && _ctx.generateToken(...args))),
            type: "button",
            class: "btn btn-light-primary me-3"
          }, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr082.svg" })
            ]),
            _cache[8] || (_cache[8] = _createTextVNode(" Generate Token "))
          ]),
          _createVNode(_component_Field, {
            type: "text",
            name: "fno",
            class: "form-control form-control-solid mb-1",
            placeholder: "Password(kosongkan jika ingin generate)",
            modelValue: _ctx.defaultPassword,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.defaultPassword) = $event))
          }, null, 8, ["modelValue"]),
          _createElementVNode("button", {
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.copyToken && _ctx.copyToken(...args))),
            type: "button",
            class: "btn btn-light-primary me-3"
          }, [
            _createElementVNode("span", _hoisted_8, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr060.svg" })
            ]),
            _cache[9] || (_cache[9] = _createTextVNode(" Copy Token "))
          ])
        ])
      ])
    ]),
    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "card-header border-0 pt-5" }, [
      _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
        _createElementVNode("span", { class: "card-label fw-bolder fs-3 mb-1" }, "Daftar Registrasi")
      ]),
      _createElementVNode("div", { class: "card-toolbar" })
    ], -1)),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("table", _hoisted_11, [
          _cache[10] || (_cache[10] = _createElementVNode("thead", null, [
            _createElementVNode("tr", { class: "border-0" }, [
              _createElementVNode("th", { class: "p-0 min-w-150px" }),
              _createElementVNode("th", { class: "p-0 min-w-100px" }),
              _createElementVNode("th", { class: "p-0 min-w-100px" }),
              _createElementVNode("th", { class: "p-0 min-w-100px" }),
              _createElementVNode("th", { class: "p-0 min-w-100px" })
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.failCache, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("a", _hoisted_14, _toDisplayString(item.Fullname) + " (" + _toDisplayString(item.Gender) + ")", 1),
                      _createElementVNode("a", _hoisted_15, _toDisplayString(item.Email), 1)
                    ])
                  ])
                ]),
                _createElementVNode("td", _hoisted_16, [
                  _createElementVNode("a", _hoisted_17, _toDisplayString(item.Dob), 1),
                  _createElementVNode("a", _hoisted_18, _toDisplayString(item.Job), 1)
                ]),
                _createElementVNode("td", _hoisted_19, [
                  (item.IsMmpi)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_20, "MMPI"))
                    : _createCommentVNode("", true),
                  (item.IsPapiKostick)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_21, "PAPI KOSTICK"))
                    : _createCommentVNode("", true),
                  (item.IsRiasec)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_22, "Riasec"))
                    : _createCommentVNode("", true),
                  (item.IsDisc)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_23, "DISC"))
                    : _createCommentVNode("", true),
                  (item.IsKraepelin)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_24, "KRAEPELIN"))
                    : _createCommentVNode("", true),
                  (item.IsIst)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_25, "IST"))
                    : _createCommentVNode("", true),
                  (item.IsCfitA)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_26, "CFIT A"))
                    : _createCommentVNode("", true),
                  (item.IsCfitB)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_27, "CFIT B"))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("td", _hoisted_28, [
                  _createElementVNode("a", _hoisted_29, "Password: " + _toDisplayString(item.Password), 1),
                  _createElementVNode("a", _hoisted_30, "Token: " + _toDisplayString(item.Token), 1)
                ]),
                _createElementVNode("td", _hoisted_31, [
                  _createElementVNode("a", {
                    class: _normalizeClass(["fs-7", item.IsFail === 1 ? 'text-danger' : 'text-success'])
                  }, _toDisplayString(item.IsFail === 1 ? 'Gagal' : 'Berhasil'), 3),
                  _createElementVNode("a", _hoisted_32, _toDisplayString(item.FailureReason), 1)
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}