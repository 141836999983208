
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as XLSX from 'xlsx';
import CryptoJS from 'crypto-js';
import ApiService from "@/core/services/ApiService";
import Services from "@/core/services/ArrService";
import ApiService2 from "@/core/services/ApiService2";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { Field } from "vee-validate";

export default defineComponent({
  name: "import-peserta",
  components: {
    Field,
  },
  setup() {
    var failCache = ref([] as Array<any>)
    var generateList = ref([] as Array<any>)
    var defaultPassword = ref("")

    onMounted(() => {
      setCurrentPageBreadcrumbs("IMPORT PESERTA", [])
    });

    const fileInput = ref<HTMLInputElement | null>(null);

    const copyToken = () =>{
      // Convert the JSON object to a string
      var jsonCopyList = ref([] as Array<any>)
      for(let i=0; i<failCache.value.length; i++){
        jsonCopyList.value.push({ Email : failCache.value[i].Email, Fullname: failCache.value[i].Fullname, Password:failCache.value[i].Password, Token:failCache.value[i].Token})
      }
      var jsonString = JSON.stringify(jsonCopyList.value, null, 2); // The second argument formats the JSON nicely
      
      // Use the Clipboard API to copy the JSON string to the clipboard
      navigator.clipboard.writeText(jsonString)
        .then(() => {
           toast("copied to clipboard!", 
            {
              autoClose: 1000,
              type: 'success',
              pauseOnFocusLoss: false,
            });
        })
        .catch((error) => {
          console.error("Failed to copy JSON:", error);
        });
    }

    // Trigger the file input click when the button is clicked
    const triggerFileInput = () => {
      fileInput.value?.click();
    };

    // Handle the file when selected
    const handleFileUpload = async (event: Event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        // console.log("File selected:", file);
        // Read the file using the FileReader API
        const reader = new FileReader();
        reader.onload = (e: any) => {
          try {
            // Convert the file content to a binary string
            const binaryStr = e.target.result;
            // Use SheetJS (XLSX) to parse the binary string
            const workbook = XLSX.read(binaryStr, { type: 'binary' });

            // Assuming the file has only one sheet, get the first sheet
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            // Convert the sheet to JSON data
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { /*header: 1*/ }); // Set header: 1 for array format

            // console.log('Parsed Excel Data:', jsonData);
            sendJsonDataToServer(jsonData);
            // Now you have the Excel data in `jsonData`, you can use it to populate your table or process it further
            // For example, you could store it in your `data` variable or pass it to your API.

          } catch (error) {
            console.error('Error parsing Excel file:', error);
          }
        };

        reader.onerror = (error) => {
          console.error('Error reading file:', error);
        };

        // Read the selected file as a binary string
        reader.readAsBinaryString(file);

        // Reset the file input so it can trigger again on re-upload
        if (fileInput.value) {
          fileInput.value.value = ''; // Reset the file input value
        }
      }
    };

    async function sendJsonDataToServer(json){
      var saveObj = {}
      // saveObj = Object.assign({}, data_save)
      saveObj['Trigger'] = 'C'
      saveObj['Route'] = 'ImportRegister'
      saveObj['CompanyId'] = localStorage.getItem('UserID')
      saveObj['RegisterList'] = []

      for(let i=0; i<json.length; i++){
        if(i==0)
          continue

        // json[i]['Password'] = CryptoJS.MD5(json[i]['Password']).toString(CryptoJS.enc.Hex);
        saveObj['RegisterList'].push(json[i])
      }

      if(saveObj['RegisterList'].length > 0){
        saveObj['RegisterList'] = JSON.stringify(saveObj['RegisterList'])
        // console.log("saveObj", saveObj)

        failCache.value = []
        await Services.PostDataXWWW(ApiService, "USER", saveObj, response=>{
          if(response.status == 1000){
            failCache.value = response.data
            toast("Save Berhasil!", 
            {
              autoClose: 1000,
              type: 'success',
              pauseOnFocusLoss: false,
            });
          }else{
            toast("Save Gagal", 
            {
              autoClose: 1000,
              type: 'error',
              pauseOnFocusLoss: false,
            });
          }
        }, err =>{
           toast("Save Gagal", 
            {
              autoClose: 1000,
              type: 'error',
              pauseOnFocusLoss: false,
            });
        })
      }
    }

    const downloadTemplate = () => {
      const fileUrl = 'template/ImportPeserta.xlsx';  // Change to the URL or path of your file
      const fileName = 'ImportPeserta.xlsx'; // Set the desired filename for the download

      // Create an anchor element
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = fileName; // Set the download attribute with the filename
      a.click(); // Programmatically trigger a click to download the file
    };

    const generateToken = () => {
      generateList.value = []
      generateList.value = failCache.value.findAllUsingEquality((obj)=>{
        return obj.IsFail == 0
      })

      if(generateList.value.length == 0){
        return toast("Tidak Ada Data", 
            {
              autoClose: 1000,
              type: 'error',
              pauseOnFocusLoss: false,
            });
      }

      sendGenerateDataToServer(generateList.value)
    };

    async function sendGenerateDataToServer(list){
      var saveObj = {}
      // saveObj = Object.assign({}, data_save)
      saveObj['Trigger'] = 'C'
      saveObj['Route'] = 'ImportGenerateToken'
      saveObj['RegisterList'] = JSON.stringify(list)
      saveObj['DefaultPassword'] = defaultPassword.value

      await Services.PostDataXWWW(ApiService, "USER", saveObj, response=>{
        if(response.status == 1000){
          for(let i=0; i<response.data.length; i++){
            var resObj = failCache.value.findFirstUsingEquality((obj)=>{
                  return obj.Email == response.data[i].Email
            })
            if(resObj != undefined){
              resObj.Token = response.data[i].Token
              resObj.Password = response.data[i].Password
            }
          }
          toast("Generate Berhasil!", 
          {
            autoClose: 1000,
            type: 'success',
            pauseOnFocusLoss: false,
          });
        }else{
          toast("Generate Gagal", 
          {
            autoClose: 1000,
            type: 'error',
            pauseOnFocusLoss: false,
          });
        }
      }, err =>{
          toast("Generate Gagal", 
          {
            autoClose: 1000,
            type: 'error',
            pauseOnFocusLoss: false,
          });
      })
    }

    return {
      failCache,
      triggerFileInput,
      handleFileUpload,
      fileInput,
      downloadTemplate,
      generateToken,
      defaultPassword,
      copyToken,
    };
  },
});
